import React, {useRef, useEffect} from 'react';
import styled from 'styled-components'
import Img from "gatsby-image"
import {colors} from "../variables/colors"


const PdfFlip = styled(({pdfUrl, title,  description,bookRef, image, className, onClickPdf, index}) => {
    const parentRef = useRef(null)

    const onClickBook = () => {
        onClickPdf({
            description,
            title,
            image,
            pdfUrl,
            index
        })
    }
    return (
        // <div onClick={e => openBookTest} ref={parentRef} id="test-div" className={className} data-rel="fh5-light-box-demo" data-href="https://online.fliphtml5.com/tloka/upls/" data-width="700" data-height="400" data-title="bachelor_pad">
        <div onClick={onClickBook} className={className}>
            {image && <Img fluid={image} />}
            <div className="title">
                {title}
            </div>
        </div>
    );
})`
  max-width: 500px;
  font-size: 15px;
  position:relative;
  width:100%;
  overflow: hidden;
  @media screen and (min-width:500px) {
    width:48%;
  }
  @media screen and (min-width:768px) {
    width:32%;
    &:nth-child(3n + 1) {
      margin-left:0;
    }
  }
  @media screen and (min-width:1024px) {
    width:24%;
    margin-left:4/3%;
    margin-bottom:2%;
    &:nth-child(3n + 1) {
      margin-left:4/3%;
    }
    &:nth-child(4n + 1) {
      margin-left:0;
    }
  }
  .title {
    text-transform: uppercase;
    text-align: center;
    margin:5px auto;
  }
  .description {
    position: absolute;
    opacity:0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${colors.blueDark};
    color: white;
    transform-origin:center top;
    transform: scaleY(0);
    transition: all .3s ease;
    display:flex;
    justify-content: center;
    align-items: center;
    div {
      padding:15px;
    }
  }
  transition: all .3s ease-in;
  transform: scale(1);
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    .description {
      opacity:1;
      transform: scaleY(1);
    }
  }
`

export default PdfFlip;
