import React, {useEffect, useState} from 'react';
// import { Document, Page } from 'react-pdf';
import styled from 'styled-components'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { AiOutlineZoomIn, AiOutlineZoomOut} from "react-icons/ai";

const ButtonWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:180px;
  border:1px solid #efecec;
  border-radius:3px;
  position:sticky;
  margin-top:10px;
  top:10px;
  left:50%;
  transform: translateX(-50%);
  button {
    outline: none;
    border:none;
    padding:5px 15px;
    &:hover {
      cursor:pointer;
    }
  }
`
const ScaleButton = styled.button`
    position:absolute;
    margin-top:15px;
    border:none;
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    box-shadow: #bfbbbb 0 0 11px 0;
    bottom: 2%;
    &:hover {
      cursor:pointer;
    }
`

const PdfBook = ({pdfUrl, removeLoader}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [disablePrev, setDisablePrev] = useState(true)
    const [disableNext, setDisableNext] = useState(false)
    const [scaleMe, setScaleMe] = useState(1.0)

    // useEffect(() => {
    //     // console.log("JQUERY: ", window.$.wowBook)
    //     // const rootPdf = window.$.wowBook("#rootPdf");
    //     window.$("#rootPdf").wowBook({
    //         container: true,
    //         containerHeight: 710,
    //         containerWidth: 1100,
    //         pdf: pdfUrl
    //     });
    // }, [pdfUrl])

    useEffect(() => {
        if(pageNumber === 1) {
            setDisablePrev(true)
        }
        if(pageNumber > 1 && disablePrev === true ) {
            setDisablePrev(false)
        }
        if(pageNumber === numPages) {
            setDisableNext(true)
        }
        if(pageNumber < numPages && disableNext === true ) {
            setDisableNext(false)
        }
    },[pageNumber])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const prevPage = () => {
        setPageNumber((prevNumber) => {
            return prevNumber - 1
        })
    }

    const nextPage = () => {
        setPageNumber((prevNumber) => {
            return prevNumber + 1
        })
    }

    return (
           // <div style={{minWidth:"500px", minHeight:"500px"}}>
        <>
            {/*<Document*/}
            {/*    file={pdfUrl}*/}
            {/*    onLoadSuccess={onDocumentLoadSuccess}*/}
            {/*>*/}
            {/*    <Page scale={scaleMe} height="800" pageNumber={pageNumber} />*/}
            {/*</Document>*/}
            {/*<ButtonWrapper>*/}
            {/*    <button disabled={disablePrev} onClick={prevPage}> &laquo; </button>*/}
            {/*    <div>{pageNumber} od {numPages}</div>*/}
            {/*    <button disabled={disableNext} onClick={nextPage}> &raquo; </button>*/}
            {/*</ButtonWrapper>*/}
            {/*<ScaleButton style={{left:"5%"}} onClick={() => setScaleMe(prev => prev - 0.2)}><AiOutlineZoomOut /></ScaleButton>*/}
            {/*<ScaleButton style={{right: "5%"}} onClick={() => setScaleMe(prev => prev + 0.2)}><AiOutlineZoomIn /></ScaleButton>*/}
            {/*<div id="rootPdf" />*/}
    </>)
};

export default PdfBook;
