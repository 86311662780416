import * as React from "react"
import styled from "styled-components"
import PdfFlip from "../components/pdfFlip";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive'
import Img from "gatsby-image";
import Zoom from "react-reveal/Zoom"
import Modal from 'react-modal';
import * as PropTypes from "prop-types";
import PdfBook from "../components/PdfBook";
import { getSectionsData} from "../utils/helpers"
import aboutBg from "../images/about_bg.jpg"
import {colors} from "../variables/colors";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { sortModulesAndAssets } from "../../plugins/berry-plugin/util/helpers"
import OgImage from "../images/og-image.jpg"

const isBrowser = typeof window !== "undefined"

const PdfWrapper = styled.div`
  box-sizing: border-box;
  display:flex;
  justify-content: center;
  flex-direction: column;
  max-width:1200px;
  margin:0 auto;
  @media screen and (min-width:768px) {
    justify-content: space-between;
  }
  @media screen and (min-width:1024px) {
    justify-content: flex-start;
  }
  width:100%;
  flex-wrap: wrap;
`

const PdfSingle = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  width:100%;
  padding:20px;
  border-bottom: 1px solid #e3e0e0;
  margin:20px auto;
`
const PdfListWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: space-between;
  flex-wrap:wrap;
`
const PdfSingleDescription = styled.div`
  width:100%;
`
const PdfSingleBook = styled.div`
  width:100%;
  @media screen and (min-width:786px) {
    width:${props => props.width ? props.width : "30%"};
  }
  img {
    display:block;
    width:100%;
  }
  &:hover {
    cursor:pointer;
  }
  .container {
    z-index: 1;
    perspective:3000px;
    .book {
      position: relative;
      display: block;
      //width: 400px;
      //height: 566px;
      margin: 5% auto;
      border-radius: 2px 4px 4px 2px;
      background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
      font-family: acumin-pro, sans-serif;
      -webkit-box-shadow: 13px 13px 8px 0px rgba(151, 146, 153,0.6);
      -moz-box-shadow: 13px 13px 8px 0px rgba(151, 146, 153,0.6);
      box-shadow: 13px 13px 8px 0px rgba(151, 146, 153,0.6);
      font-weight: 400;
      color: #2b2b2b;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: -webkit-transform .5s;
      -moz-transition: -moz-transform .5s;
      transition: transform .5s;
      &:hover {
        -webkit-transform: rotate3d(0,1,0,35deg);
        -moz-transform: rotate3d(0,1,0,35deg);
        transform: rotate3d(0,1,0,35deg);
      }
      > div {
        display: block;
        //position: absolute;
      }
    }
    .front {
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform-origin: 0% 50%;
      -moz-transform-origin: 0% 50%;
      transform-origin: 0% 50%;
      -webkit-transition: -webkit-transform .5s;
      -moz-transition: -moz-transform .5s;
      transition: transform .5s;
      -webkit-transform: translate3d(0,0,20px);
      -moz-transform: translate3d(0,0,20px);
      transform: translate3d(0,0,20px);
      z-index: 10;
      &:after {
        content: '';
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: -1px;
        width: 1px;
      }
      > div {
        display: block;
        //position: absolute;
        border-radius: 0 3px 3px 0;
        box-shadow: inset 4px 0 10px rgba(0, 0, 0, 0.1);
        //width: 400px;
        //height: 566px;
      }
    }
    .left-side {
      position: absolute;
      width: 40px;
      left: -20px;
      top:0;
      bottom:0;
      //height: 550px;
      background-color: rgba(232,229,234);
      -webkit-transform: rotate3d(0,1,0,-90deg);
      -moz-transform: rotate3d(0,1,0,-90deg);
      transform: rotate3d(0,1,0,-90deg);
    }
    .cover {
      background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
      padding: 0 0 0 13px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        bottom: 0;
        width: 3px;
        background: rgba(0,0,0,0.1);
        box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
      }
    }
  }
`
const PdfTitle = styled.h2`
  width:100%;
  text-align: center;
`
const HiddenTextDiv = styled.div`
 width: 100%;
  margin-top: 20px !important;
`

export function GFDropdown(props) {
    return (
        <SlideDown className={'gf-dropdown-slidedown'}>
            {props.open ? props.children : null}
        </SlideDown>
    )
}

const PdfInfoWrapper = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  width:100%;
  margin-bottom:30px;
  @media screen and (min-width:786px) {
    width:70%;
    margin-bottom:0;
  }
`


// Create styles
let book ;
const bookArr = []
function CPdfSingle({title, description, show, fluid, allPdfs, onBookSelected, isDesktop, fileUrl, pdfIndex}) {
    const [showPdfSingle, setShowPdfSingle] = useState(false)

    Array.from(Array(allPdfs).keys()).forEach((el,index) => {
            bookArr.push(null)
    })

    useEffect(() => {
        if(!bookArr[pdfIndex]) {
            window.$("#rootPdf_" + pdfIndex).wowBook({
                lightbox: `#pdfTrigger_${pdfIndex}`,
                toolbar: "first, back, next, last,zoomin, zoomout",
                onShowLightbox: (book) => {
                    console.log("MARE CARE KUPI MI BANANE")
                },
                onHideLightbox: (book) => {
                    book.showPage(0)
                },
                pdf: fileUrl
            });
            bookArr[pdfIndex] = window.$.wowBook("#rootPdf_" + pdfIndex)
            // console.log(bookArr[pdfIndex])
        }
    },[fileUrl, pdfIndex])

    const showPdf = () => {
        // onBookSelected(true)
    }
    return <PdfSingle>
        <PdfInfoWrapper>
            <PdfTitle>
                <Zoom when={show}>
                    {title}
                </Zoom>
            </PdfTitle>
            <PdfSingleDescription>
                <Zoom when={show}>
                    <div dangerouslySetInnerHTML={{__html: description}} />
                </Zoom>
            </PdfSingleDescription>
        </PdfInfoWrapper>
        {isDesktop ? <PdfSingleBook onClick={showPdf}>

            <Zoom when={show}>
                <div className="container">
                <div className="book">
                    <div className="front">
                        <div className="cover">
                            <Img fluid={fluid}/>
                        </div>
                    </div>
                    <div className="left-side" />
                </div>
            </div>
                {[...Array(allPdfs).keys()].map((pdf,index) => {
                    return  <ReadMoreBtn style={{display: index === pdfIndex ? 'block' : 'none'}} key={`${index}_btnread`} id={`pdfTrigger_${index}`} margin="5px auto">
                        Pročitaj više
                    </ReadMoreBtn>
                })}
                {/*<ReadMoreBtn id={`pdfTrigger_${pdfIndex}`} margin="5px auto">*/}
                {/*    Pročitaj više*/}
                {/*</ReadMoreBtn>*/}
            </Zoom>
        </PdfSingleBook> :  (
            <a style={{width:"100%",margin:"auto", textDecoration:"none"}} href={fileUrl}>
            <PdfSingleBook width="100%">
                <Zoom when={show}>
                    <Img fluid={fluid}/>
                        <ReadMoreBtn id={`pdfTrigger_${pdfIndex}`}>
                            Pročitaj više
                        </ReadMoreBtn>
                </Zoom>
            </PdfSingleBook>
        </a>
            )
        }


    </PdfSingle>
}

CPdfSingle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    when: PropTypes.bool,
    fluid: PropTypes.any
};
export const ReadMoreBtn = styled.button`
  background-color: ${colors.orangeDark};
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  margin: ${props => props.margin ? props.margin : "5px auto"};
  &:hover {
    cursor: pointer;
  }
`
const TopHeader = styled.div`
  background-image: url(${aboutBg});
  background-repeat: repeat-y;
  padding:30px 20px;
  div:first-child {
    max-width:1200px;
    margin:0 auto;
  }
`

const IndexPage = ({data, pageContext, location}) => {
    const lang = pageContext.lang
    const secData = getSectionsData(data.template.sections)
    const metaInfo = data.template.locales.find(l => l.iso === lang)
    console.log("META INFO: ", metaInfo)
    // console.log("SECTION DATA: ", secData)
    const [showSingle, setShowSingle] = useState(false)
    const [openHiddenText, setOpenHiddenText] = useState(false)
    const [openText, setOpenText] = useState(false)
    const bookRef = React.useRef(null)
    const scrollRef = React.useRef(null)
    const pdfs = data.allPress.nodes
    const [showModal, setShowModal] = useState(false)
    const [ description, setDescription] = useState("")
    const [ title, setTitle ] = useState("")
    const [pdfUrl, setPdfUrl ] = useState("")
    const [ image , setImage ] = useState(null)
    const [pdfIndex, setPdfIndex] = useState(null)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const onBookSelected = (showModal) => {
        // console.log("PDF URL: ", pdfUrl)
        if(isDesktopOrLaptop) {
            setShowModal(showModal)
        }
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            height:'95%',
            transform: 'translate(-50%, -50%)',
            overflow:"hidden",
            maxWidth: "1200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    };
    // useEffect(() => {
    //     if(isBrowser) {
    //         Modal.setAppElement('#modal');
    //     }
    // },[])
    useEffect(() => {
        if(openHiddenText) {
            setOpenText(true)
        }
    }, [openHiddenText])

    const onClickPdf = (pdf) => {
        setShowSingle(false)
        setPdfIndex(pdf.index)
        setTitle(pdf.title)
        setDescription(pdf.description)
        setImage(pdf.image)
        setPdfUrl(pdf.pdfUrl)
        scrollRef.current.scrollIntoView({block: "start", behavior: "smooth"})
        setTimeout(() => {
            setShowSingle(true)
        },100)
    }
    const openedText = secData.header.Description[lang]
    const hiddenText = secData.header['Description Second'][lang]

    return <Layout>
        <Seo
            title={metaInfo.metaTitle}
            description={metaInfo.metaDescription}
            keywords={metaInfo.metaKeywords}
            ogUrl={location.href}
            ogImage={data.ographImage.publicURL}
        />
        <TopHeader>
             <div>
                 <h2>{secData.header.Title[lang]}</h2>
                 <div dangerouslySetInnerHTML={{__html: openedText}} />
                 <a href="#" style={{textDecoration: "none", color: colors.orangePrimary}} onClick={(e) => { e.preventDefault(); setOpenHiddenText(prev => !prev)}}>{openHiddenText ? 'Pročitaj manje...': 'Pročitaj više...'}</a>
                 <GFDropdown open={openHiddenText}>
                     <HiddenTextDiv className={openHiddenText ? 'open' : null} dangerouslySetInnerHTML={{__html: hiddenText}} />
                 </GFDropdown>

             </div>
        </TopHeader>
        <PdfWrapper ref={scrollRef}>
            {/*{ showSingle && <CPdfSingle title={title} description={description} fluid={image}/> }*/}
            { !title ? <h4 style={{
                textAlign:"center",
                width:"100%",
                padding:"20px 0",
                margin:"20px auto",
                borderBottom: "1px solid #e3e0e0",
                color: colors.orangePrimary
            }}>Izaberite časopis</h4> : <CPdfSingle allPdfs={pdfs.length} pdfIndex={pdfIndex} title={title} fileUrl={pdfUrl} isDesktop={isDesktopOrLaptop} description={description} fluid={image} show={showSingle} onBookSelected={onBookSelected}/> }
            <PdfListWrapper>
                {pdfs && pdfs.length > 0 && sortModulesAndAssets(pdfs).map((pdf, index) => {
                    const image = pdf.imageFile.childImageSharp.fluid
                    const pdfUrl = pdf.hardCopyFile.publicURL
                    const desc = pdf.locales[0].content
                    const title = pdf.locales[0].title
                    return <React.Fragment key={pdf.uid}><PdfFlip onClickPdf={onClickPdf} title={title} description={desc} image={image}
                                    bookRef={bookRef} pdfUrl={pdfUrl} index={index}/>
                        <div id={`rootPdf_${index}`} />
                    </React.Fragment>
                })}
            </PdfListWrapper>
        </PdfWrapper>
        {/*<div id="rootPdf" />*/}
        {/*<Modal*/}
        {/*    style={customStyles}*/}
        {/*    isOpen={showModal}*/}
        {/*    onRequestClose={closeModal}*/}
        {/*>*/}
        {/*   /!*<div style={{overflow:"auto", position:"relative", paddingBottom:"20px"}}>*!/*/}
        {/*    <div>*/}
        {/*        <PdfBook pdfUrl={pdfUrl} />*/}
        {/*    </div>*/}

        {/*</Modal>*/}
        {/*<div id="modal" />*/}
    </Layout>
}

export const query = graphql`
  query HomePage($templateName:String){
   ographImage:file(relativePath: {eq: "og-image.jpg"}) {
    publicURL
  }
  template(name: {eq: $templateName}) {
    locales {
        iso
        metaTitle
        metaDescription
        metaKeywords
    }
    sections {
      items {
        key
        ... on DataItemTextGet {
          __typename
          locales {
            iso
            value
          }
        }
      }
      keyPrefix
    }
  }
   allPress {
      nodes {
        uid
        position
        locales {
          iso
          metaTitle
          metaDescription
          title
          value
          description
          content
        }
       additionalData {
          key
          locales {
            iso
            value
          }
        }
        hardCopyFile {
          publicURL
        }
        imageFile {
          childImageSharp {
            fluid(maxWidth:800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
